import { USER_ROLE, UserResponse } from '../providers/User/User.model';

// import Intercom from '@intercom/messenger-js-sdk';

function startIntercom(user: UserResponse | undefined): void {
  let userData = {};

  if (user) {
    userData = {
      user_id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email_address,
      company: {
        id: user.tenant?.id,
        name: user.tenant?.company_name
      },
      user_hash: user.intercom_user_hash
    };
  }

  window.zE('webWidget', 'hide');
  window.Intercom('boot', { app_id: 'vdunphop', ...userData });
}

function startZendesk(): void {
  window.Intercom('shutdown');
  window.zE('webWidget', 'show');
}

export function hideWidget(): void {
  window.Intercom('shutdown');
  window.zE('webWidget', 'hide');
}

export function showWidget(enableIntercomFlag: boolean, user: UserResponse | undefined): void {
  const userRole = user?.role;

  if (!userRole || userRole === USER_ROLE.CUSTOMER_CONTACT) return;
  enableIntercomFlag ? startIntercom(user) : startZendesk();
}
