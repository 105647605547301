/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Features } from './FeatureFlags.model';

export interface FeatureFlagsContextProps {
  enableIntegrationsSettingsPage: boolean;
  enableAccountSettingsPage: boolean;
  enableSettingsPageAccess: boolean;
  enableChargbeeTrialBanner: boolean;
  enableShowWelcome: boolean;
  enableInvoices: boolean;
  enableTruckbaseInvoicing: boolean;
  enableDynamicWebTwain: boolean;
  enableShowInbox: boolean;
  enableEdiImports: boolean;
  enableEmailImports: boolean;
  enableShowCalculateEffectiveRateButton: boolean;
  enableCreateLoadFromPdf: boolean;
  enableBulkLoadImport: boolean;
  enableDriverGrossPayEstimate: boolean;
  enableTeamDrivers: boolean;
  enableSplitLoads: boolean;
  enableSendInvoiceOnMobile: boolean;
  enableDriverSettlements: boolean;
  enableLiveTracking: boolean;
  enableCustomerPortal: boolean;
  enableRouteInfoV2: boolean;
  enableRateConBanner: boolean;
  enableFuelTransactions: boolean;
  enableMetabase: boolean;
  enableAddressLocationLink: boolean;
  enableOrganizations: boolean;
  enableStopSignature: boolean;
  enableQboSetCustomLoadField: boolean;
  enableMaintenanceLog: boolean;
  enableAutomaticallyFilterDispatchUserLoads: boolean;
  enableOtherExpenses: boolean;
  enableSendQboInvoiceInternally: boolean;
  enableFlexibleTagging: boolean;
  enableDownloadAppBanner: boolean;
  enableZipAttachments: boolean;
  enableCalendarV2: boolean;
  enableOtherPayInDriverPayModal: boolean;
  enableButlerRateConModelDropdown: boolean;
  enableDummyDeleteAccountButton?: boolean;
  enableMarkAsDeliveredBtn: boolean;
  enableSystemCameraForDocumentUpload?: boolean;
  enableShowDriverProfile: boolean;
  enableFactoring: boolean;
  enableIntercomWidget: boolean;
  enableReportsUnderConstruction: boolean;
  enableAvailabilityPage: boolean;
  enableMobileLoadInbox: boolean;
  enableEquipmentFile: boolean;
  enableNotificationBanner?: Record<string, string>;
  enableDownForMaintenance?: boolean;
  enableAndroidCreateGeoLink?: boolean;
  enableWarningHelper?: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextProps>({
  enableIntegrationsSettingsPage: false,
  enableAccountSettingsPage: false,
  enableSettingsPageAccess: false,
  enableChargbeeTrialBanner: false,
  enableShowWelcome: false,
  enableInvoices: false,
  enableTruckbaseInvoicing: false,
  enableDynamicWebTwain: false,
  enableShowInbox: false,
  enableEdiImports: false,
  enableEmailImports: false,
  enableShowCalculateEffectiveRateButton: false,
  enableCreateLoadFromPdf: false,
  enableBulkLoadImport: false,
  enableDriverGrossPayEstimate: false,
  enableTeamDrivers: false,
  enableSplitLoads: false,
  enableSendInvoiceOnMobile: false,
  enableDriverSettlements: false,
  enableLiveTracking: false,
  enableCustomerPortal: false,
  enableRouteInfoV2: false,
  enableRateConBanner: false,
  enableFuelTransactions: false,
  enableMetabase: false,
  enableAddressLocationLink: false,
  enableOrganizations: false,
  enableStopSignature: false,
  enableQboSetCustomLoadField: false,
  enableMaintenanceLog: false,
  enableAutomaticallyFilterDispatchUserLoads: false,
  enableOtherExpenses: false,
  enableSendQboInvoiceInternally: false,
  enableFlexibleTagging: false,
  enableDownloadAppBanner: false,
  enableZipAttachments: false,
  enableCalendarV2: false,
  enableOtherPayInDriverPayModal: false,
  enableButlerRateConModelDropdown: false,
  enableDummyDeleteAccountButton: false,
  enableMarkAsDeliveredBtn: false,
  enableSystemCameraForDocumentUpload: false,
  enableShowDriverProfile: false,
  enableFactoring: false,
  enableIntercomWidget: false,
  enableReportsUnderConstruction: false,
  enableMobileLoadInbox: false,
  enableEquipmentFile: false,
  enableAvailabilityPage: false,
  enableNotificationBanner: {},
  enableDownForMaintenance: false,
  enableWarningHelper: false,
  enableAndroidCreateGeoLink: false
});

FeatureFlagsContext.displayName = 'FeatureFlagsProvider';

export const FeatureFlagsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [enableSettingsPageAccess, setEnableSettingsPageAccess] = useState<boolean>(false);
  const [enableIntegrationsSettingsPage, setEnableIntegrationsSettingsPage] =
    useState<boolean>(false);
  const [enableAccountSettingsPage, setEnableAccountSettingsPage] = useState<boolean>(false);
  const [enableChargbeeTrialBanner, setEnableChargbeeTrialBanner] = useState<boolean>(false);
  const [enableShowWelcome, setEnableShowWelcome] = useState<boolean>(false);
  const [enableInvoices, setEnableInvoices] = useState<boolean>(false);
  const [enableTruckbaseInvoicing, setEnableTruckbaseInvoicing] = useState<boolean>(false);
  const [enableDynamicWebTwain, setEnableDynamicWebTwain] = useState<boolean>(false);
  const [enableShowInbox, setEnableShowInbox] = useState<boolean>(false);
  const [enableEdiImports, setEnableEdiImports] = useState<boolean>(false);
  const [enableEmailImports, setEnableEmailImports] = useState<boolean>(false);
  const [enableShowCalculateEffectiveRateButton, setEnableShowCalculateEffectiveRateButton] =
    useState<boolean>(false);
  const [enableCreateLoadFromPdf, setEnableCreateLoadFromPdf] = useState<boolean>(false);
  const [enableBulkLoadImport, setEnableBulkLoadImport] = useState<boolean>(false);
  const [enableDriverGrossPayEstimate, setEnableDriverGrossPayEstimate] = useState<boolean>(false);
  const [enableTeamDrivers, setEnableTeamDrivers] = useState<boolean>(false);
  const [enableSplitLoads, setEnableSplitLoads] = useState<boolean>(false);
  const [enableSendInvoiceOnMobile, setEnableSendInvoiceOnMobile] = useState<boolean>(false);
  const [enableDriverSettlements, setEnableDriverSettlements] = useState<boolean>(false);
  const [enableLiveTracking, setEnableLiveTracking] = useState<boolean>(false);
  const [enableCustomerPortal, setEnableCustomerPortal] = useState<boolean>(false);
  const [enableRouteInfoV2, setEnableRouteInfoV2] = useState<boolean>(false);
  const [enableRateConBanner, setEnableRateConBanner] = useState<boolean>(false);
  const [enableFuelTransactions, setEnableFuelTransactions] = useState<boolean>(false);
  const [enableMetabase, setEnableMetabase] = useState<boolean>(false);
  const [enableQboSetCustomLoadField, setEnableQboSetCustomLoadField] = useState<boolean>(false);

  const [enableAddressLocationLink, setEnableAddressLocationLink] = useState<boolean>(false);
  const [enableOrganizations, setEnableOrganizations] = useState<boolean>(false);
  const [enableStopSignature, setEnableStopSignature] = useState<boolean>(false);
  const [enableMaintenanceLog, setEnableMaintenanceLog] = useState<boolean>(false);
  const [
    enableAutomaticallyFilterDispatchUserLoads,
    setEnableAutomaticallyFilterDispatchUserLoads
  ] = useState<boolean>(false);
  const [enableOtherExpenses, setEnableOtherExpenses] = useState<boolean>(false);
  const [enableSendQboInvoiceInternally, setEnableSendQboInvoiceInternally] =
    useState<boolean>(false);
  const [enableFlexibleTagging, setEnableFlexibleTagging] = useState<boolean>(false);
  const [enableDownloadAppBanner, setEnableDownloadAppBanner] = useState<boolean>(false);
  const [enableZipAttachments, setEnableZipAttachments] = useState<boolean>(false);
  const [enableCalendarV2, setEnableCalendarV2] = useState<boolean>(false);
  const [enableOtherPayInDriverPayModal, setEnableOtherPayInDriverPayModal] =
    useState<boolean>(false);
  const [enableButlerRateConModelDropdown, setEnableButlerRateConModelDropdown] =
    useState<boolean>(false);
  const [enableDummyDeleteAccountButton, setEnableDummyDeleteAccountButton] =
    useState<boolean>(false);
  const [enableMarkAsDeliveredBtn, setEnableMarkAsDeliveredBtn] = useState<boolean>(false);
  const [enableSystemCameraForDocumentUpload, setEnableSystemCameraForDocumentUpload] =
    useState<boolean>(false);
  const [enableShowDriverProfile, setEnableShowDriverProfile] = useState<boolean>(false);
  const [enableFactoring, setEnableFactoring] = useState<boolean>(false);
  const [enableIntercomWidget, setEnableIntercomWidget] = useState<boolean>(false);
  const [enableReportsUnderConstruction, setEnableReportsUnderConstruction] =
    useState<boolean>(false);
  const [enableAvailabilityPage, setEnableAvailabilityPage] = useState<boolean>(false);
  const [enableMobileLoadInbox, setEnableMobileLoadInbox] = useState<boolean>(false);
  const [enableEquipmentFile, setEnableEquipmentFile] = useState<boolean>(false);
  const [enableNotificationBanner, setEnableNotificationBanner] = useState<Record<string, string>>(
    {}
  );
  const [enableDownForMaintenance, setEnableDownForMaintenance] = useState<boolean>(false);
  const [enableAndroidCreateGeoLink, setEnableAndroidCreateGeoLink] = useState<boolean>(false);
  const [enableWarningHelper, setEnableWarningHelper] = useState<boolean>(false);

  const {
    quickbooksOnline,
    settingsPage,
    accountSettingsPage,
    driverSignUp,
    chargbeeTrialBanner,
    showWelcome,
    showInvoices,
    truckbaseInvoicing,
    dynamicWebTwain,
    showInbox,
    showCalculateEffectiveRate,
    createLoadFromPdf,
    showDriverGrossPayEstimate,
    teamDrivers,
    splitLoads,
    createInvoiceOnMobile,
    driverSettlements,
    ediImports,
    emailImports,
    customerPortal,
    liveTracking,
    routecomponentV2,
    showUnsupportedRateConBanner,
    fuelTransactions,
    metabase,
    addressLocationLink,
    showOrganizations,
    stopSignature,
    qboSetCustomLoadField,
    maintenanceLog,
    automaticallyFilterDispatchUserLoads,
    otherExpenses,
    sendQboInvoiceInternally,
    flexibleTagging,
    downloadAppBanner,
    zipAttachments,
    calendarV2,
    otherPayItemsInDriverPay,
    butlerRateConModelDropdown,
    dummyDeleteAccountButton,
    bulkLoadImport,
    enableMarkAsDelivered,
    systemCameraForDocumentUpload,
    showDriverProfile,
    factoring,
    intercomWidget,
    reportsUnderConstruction,
    mobileLoadInbox,
    equipmentFile,
    availabilityPage,
    notificationBanner,
    downForMaintenance,
    warningHelper,
    androidCreateGeoLink
  } = useFlags();

  /**
   * Toggles a feature on/off given an condition
   * @param feature - The feature to toggle
   * @param enabled - True / False
   */
  const toggleFeature = (feature: Features, enabled: boolean | Record<string, string>): void => {
    if (typeof enabled === 'object') {
      switch (feature) {
        case Features.notificationBanner:
          setEnableNotificationBanner(enabled);
          break;
      }
    } else
      switch (feature) {
        case Features.settingsPageAccess:
          setEnableSettingsPageAccess(enabled);
          break;
        case Features.accountSettingsPage:
          setEnableAccountSettingsPage(enabled);
          break;
        case Features.integrationsSettingsPage:
          setEnableIntegrationsSettingsPage(enabled);
          break;
        case Features.chargbeeTrialBanner:
          setEnableChargbeeTrialBanner(enabled);
          break;
        case Features.showWelcome:
          setEnableShowWelcome(enabled);
          break;
        case Features.showInvoices:
          setEnableInvoices(enabled);
          break;
        case Features.truckbaseInvoicing:
          setEnableTruckbaseInvoicing(enabled);
          break;
        case Features.dynamicWebTwain:
          setEnableDynamicWebTwain(enabled);
          break;
        case Features.showInbox:
          setEnableShowInbox(enabled);
          break;
        case Features.showCalculateEffectiveRate:
          setEnableShowCalculateEffectiveRateButton(enabled);
          break;
        case Features.createLoadFromPdf:
          setEnableCreateLoadFromPdf(enabled);
          break;
        case Features.bulkLoadImport:
          setEnableBulkLoadImport(enabled);
          break;
        case Features.showDriverGrossPayEstimate:
          setEnableDriverGrossPayEstimate(enabled);
          break;
        case Features.teamDrivers:
          setEnableTeamDrivers(enabled);
          break;
        case Features.splitLoads:
          setEnableSplitLoads(enabled);
          break;
        case Features.createInvoiceOnMobile:
          setEnableSendInvoiceOnMobile(enabled);
          break;
        case Features.driverSettlements:
          setEnableDriverSettlements(enabled);
          break;
        case Features.ediImports:
          setEnableEdiImports(enabled);
          break;
        case Features.emailImports:
          setEnableEmailImports(enabled);
          break;
        case Features.liveTracking:
          setEnableLiveTracking(enabled);
          break;
        case Features.customerPortal:
          setEnableCustomerPortal(enabled);
          break;
        case Features.routeInfoV2:
          setEnableRouteInfoV2(enabled);
          break;
        case Features.addressLocationLink:
          setEnableAddressLocationLink(enabled);
          break;
        case Features.rateConBanner:
          setEnableRateConBanner(enabled);
          break;
        case Features.fuelTransactions:
          setEnableFuelTransactions(enabled);
          break;
        case Features.metabase:
          setEnableMetabase(enabled);
          break;
        case Features.showOrganizations:
          setEnableOrganizations(enabled);
          break;
        case Features.stopSignature:
          setEnableStopSignature(enabled);
          break;
        case Features.qboSetCustomLoadField:
          setEnableQboSetCustomLoadField(enabled);
          break;
        case Features.maintenanceLog:
          setEnableMaintenanceLog(enabled);
          break;
        case Features.automaticallyFilterDispatchUserLoads:
          setEnableAutomaticallyFilterDispatchUserLoads(enabled);
          break;
        case Features.otherExpenses:
          setEnableOtherExpenses(enabled);
          break;
        case Features.sendQboInvoiceInternally:
          setEnableSendQboInvoiceInternally(enabled);
          break;
        case Features.flexibleTagging:
          setEnableFlexibleTagging(enabled);
          break;
        case Features.downloadAppBanner:
          setEnableDownloadAppBanner(enabled);
          break;
        case Features.zipAttachments:
          setEnableZipAttachments(enabled);
          break;
        case Features.calendarV2:
          setEnableCalendarV2(enabled);
          break;
        case Features.otherPayInDriverPayModal:
          setEnableOtherPayInDriverPayModal(enabled);
          break;
        case Features.butlerRateConModelDropdown:
          setEnableButlerRateConModelDropdown(enabled);
          break;
        case Features.dummyDeleteAccountButton:
          setEnableDummyDeleteAccountButton(enabled);
          break;
        case Features.systemCameraForDocumentUpload:
          setEnableSystemCameraForDocumentUpload(enabled);
          break;
        case Features.enableMarkAsDelivered:
          setEnableMarkAsDeliveredBtn(enabled);
          break;
        case Features.showDriverProfile:
          setEnableShowDriverProfile(enabled);
          break;
        case Features.factoring:
          setEnableFactoring(enabled);
          break;
        case Features.intercomWidget:
          setEnableIntercomWidget(enabled);
          break;
        case Features.reportsUnderConstruction:
          setEnableReportsUnderConstruction(enabled);
          break;
        case Features.mobileLoadInbox:
          setEnableMobileLoadInbox(enabled);
          break;
        case Features.equipmentFile:
          setEnableEquipmentFile(enabled);
          break;
        case Features.availabilityPage:
          setEnableAvailabilityPage(enabled);
          break;
        case Features.downForMaintenance:
          setEnableDownForMaintenance(enabled);
          break;
        case Features.androidCreateGeoLink:
          setEnableAndroidCreateGeoLink(enabled);
          break;
        case Features.warningHelper:
          setEnableWarningHelper(enabled);
          break;
      }
  };

  // Settings page access
  const toggleSettingsPageAccess = (): void => {
    toggleFeature(Features.settingsPageAccess, settingsPage);
  };

  // Account page access
  const toggleAccountPageAccess = (): void => {
    toggleFeature(Features.accountSettingsPage, accountSettingsPage);
  };

  // Integrations page access
  const toggleIntegrationsPageAccess = (): void => {
    toggleFeature(Features.integrationsSettingsPage, quickbooksOnline);
  };

  // Driver sign up in Public Load
  const toggleDriverSignUp = (): void => {
    toggleFeature(Features.driverSignUp, driverSignUp);
  };

  // chargebee trial banner
  const toggleChargbeeTrialBanner = (): void => {
    toggleFeature(Features.chargbeeTrialBanner, chargbeeTrialBanner);
  };

  // show welcome
  const toggleShowWelcome = (): void => {
    toggleFeature(Features.showWelcome, showWelcome);
  };

  // show invoices
  const toggleInvoices = (): void => {
    toggleFeature(Features.showInvoices, showInvoices);
  };

  // enable Truckbase invoicing
  const toggleTruckbaseInvoicing = (): void => {
    toggleFeature(Features.truckbaseInvoicing, truckbaseInvoicing);
  };

  // dynamic Web Twain
  const toggleDynamicWebTwain = (): void => {
    toggleFeature(Features.dynamicWebTwain, dynamicWebTwain);
  };

  // Show inbox
  const toggleShowInbox = (): void => {
    // Inbox visibility depends on EDI, Customer Portal,  or Email imports visibility
    toggleFeature(Features.showInbox, showInbox && (ediImports || emailImports || customerPortal));
  };

  // Show EDI imports in Inbox
  const toggleEdiImports = (): void => {
    toggleFeature(Features.ediImports, ediImports);
  };

  // Show Email imports in Inbox
  const toggleEmailImports = (): void => {
    toggleFeature(Features.emailImports, emailImports);
  };

  // show Calculate Effective Rate Button
  const toggleShowCalculateEffectiveRateButton = (): void => {
    toggleFeature(Features.showCalculateEffectiveRate, showCalculateEffectiveRate);
  };

  // show Driver gross pay estimate calculator
  const toggleShowDriverGrossPayEstimate = (): void => {
    toggleFeature(Features.showDriverGrossPayEstimate, showDriverGrossPayEstimate);
  };

  // show button to create load from pdf upload
  const toggleCreateLoadFromPdf = (): void => {
    toggleFeature(Features.createLoadFromPdf, createLoadFromPdf);
  };

  const toggleBulkLoadImport = (): void => {
    toggleFeature(Features.bulkLoadImport, bulkLoadImport);
  };

  // Team drivers
  const toggleTeamDrivers = (): void => {
    toggleFeature(Features.teamDrivers, teamDrivers);
  };

  // Split Loads
  const toggleSplitLoads = (): void => {
    toggleFeature(Features.splitLoads, splitLoads);
  };

  // Create Invoice on Mobile
  const toggleCreateInvoiceOnMobile = (): void => {
    toggleFeature(Features.createInvoiceOnMobile, createInvoiceOnMobile);
  };

  // Driver Settlements
  const toggleDriverSettlements = (): void => {
    toggleFeature(Features.driverSettlements, driverSettlements);
  };

  // Live Tracking
  const toggleLiveTracking = (): void => {
    toggleFeature(Features.liveTracking, liveTracking);
  };

  // Customer Portal
  const toggleCustomerPortal = (): void => {
    toggleFeature(Features.customerPortal, customerPortal);
  };

  const toggleRouteInfoV2 = (): void => {
    toggleFeature(Features.routeInfoV2, routecomponentV2);
  };

  // Use Address in Location Link
  const toggleAddressLocationLink = (): void => {
    toggleFeature(Features.addressLocationLink, addressLocationLink);
  };

  // Rate Confirmation Banner
  const toggleRateConBanner = (): void => {
    toggleFeature(Features.rateConBanner, showUnsupportedRateConBanner);
  };

  // Fuel Transactions
  const toggleFuelTransactions = (): void => {
    toggleFeature(Features.fuelTransactions, fuelTransactions);
  };

  // Metabase
  const toggleMetabase = (): void => {
    toggleFeature(Features.metabase, metabase);
  };

  // Hide/show Organizations tab
  const toggleOrganizations = (): void => {
    toggleFeature(Features.showOrganizations, showOrganizations);
  };

  // Show Stop signature
  const toggleStopSignature = (): void => {
    toggleFeature(Features.stopSignature, stopSignature);
  };

  const toggleQboSetCustomLoadField = (): void => {
    toggleFeature(Features.qboSetCustomLoadField, qboSetCustomLoadField);
  };

  // Maintenance Log
  const toggleMaintenanceLog = (): void => {
    toggleFeature(Features.maintenanceLog, maintenanceLog);
  };

  // Automatically filter dispatch user loads
  const toggleAutomaticallyFilterDispatchUserLoads = (): void => {
    toggleFeature(
      Features.automaticallyFilterDispatchUserLoads,
      automaticallyFilterDispatchUserLoads
    );
  };

  // Other Expenses
  const toggleOtherExpenses = (): void => {
    toggleFeature(Features.otherExpenses, otherExpenses);
  };

  // Send QBO invoice internally
  const toggleSendQboInvoiceInternally = (): void => {
    toggleFeature(Features.sendQboInvoiceInternally, sendQboInvoiceInternally);
  };

  // Flexible Tagging
  const toggleFlexibleTagging = (): void => {
    toggleFeature(Features.flexibleTagging, flexibleTagging);
  };

  // Download App Banner
  const toggleDownloadAppBanner = (): void => {
    toggleFeature(Features.downloadAppBanner, downloadAppBanner);
  };

  // Zip Attachments
  const toggleZipAttachments = (): void => {
    toggleFeature(Features.zipAttachments, zipAttachments);
  };

  // Calendar V2
  const toggleCalendarV2 = (): void => {
    toggleFeature(Features.calendarV2, calendarV2);
  };

  // Other Pay Items In Driver Pay Modal
  const toggleOtherPayInDriverPayModal = (): void => {
    toggleFeature(Features.otherPayInDriverPayModal, otherPayItemsInDriverPay);
  };

  // Drop down to see rate con model options on import modal
  const toggleRateConModelDropdown = (): void => {
    toggleFeature(Features.butlerRateConModelDropdown, butlerRateConModelDropdown);
  };

  const toggleDummyDeleteAccountButton = (): void => {
    toggleFeature(Features.dummyDeleteAccountButton, dummyDeleteAccountButton);
  };

  const toggleMarkAsDelivered = (): void => {
    toggleFeature(Features.enableMarkAsDelivered, enableMarkAsDelivered);
  };

  const toggleSystemCameraForDocumentUpload = (): void => {
    toggleFeature(Features.systemCameraForDocumentUpload, systemCameraForDocumentUpload);
  };

  // show driver profile
  const toggleShowDriverProfile = (): void => {
    toggleFeature(Features.showDriverProfile, showDriverProfile);
  };

  // enable factoring
  const toggleFactoring = (): void => {
    toggleFeature(Features.factoring, factoring);
  };

  const toggleIntercomWidget = (): void => {
    toggleFeature(Features.intercomWidget, intercomWidget);
  };

  // show reports under construction tab in reports page
  const toggleReportsUnderConstruction = (): void => {
    toggleFeature(Features.reportsUnderConstruction, reportsUnderConstruction);
  };

  // availability page
  const toggleAvailabilityPage = (): void => {
    toggleFeature(Features.availabilityPage, availabilityPage);
  };

  const toggleMobileLoadInbox = (): void => {
    toggleFeature(Features.mobileLoadInbox, mobileLoadInbox);
  };

  // enable equipment file
  const toggleEquipmentFile = (): void => {
    toggleFeature(Features.equipmentFile, equipmentFile);
  };

  const toggleNotificationBanner = (): void => {
    toggleFeature(Features.notificationBanner, notificationBanner);
  };

  const toggleDownForMaintenance = (): void => {
    toggleFeature(Features.downForMaintenance, downForMaintenance);
  };

  const toggleAndroidCreateGeoLink = (): void => {
    toggleFeature(Features.androidCreateGeoLink, androidCreateGeoLink);
  };

  const toggleWarningHelper = (): void => {
    toggleFeature(Features.warningHelper, warningHelper);
  };

  useEffect(() => {
    toggleSettingsPageAccess();
  }, [settingsPage]);

  useEffect(() => {
    toggleAccountPageAccess();
  }, [accountSettingsPage]);

  useEffect(() => {
    toggleIntegrationsPageAccess();
  }, [quickbooksOnline]);

  useEffect(() => {
    toggleDriverSignUp();
  }, [driverSignUp]);

  useEffect(() => {
    toggleChargbeeTrialBanner();
  }, [chargbeeTrialBanner]);

  useEffect(() => {
    toggleShowWelcome();
  }, [showWelcome]);

  useEffect(() => {
    toggleInvoices();
  }, [showInvoices]);

  useEffect(() => {
    toggleTruckbaseInvoicing();
  }, [truckbaseInvoicing]);

  useEffect(() => {
    toggleDynamicWebTwain();
  }, [dynamicWebTwain]);

  useEffect(() => {
    toggleShowInbox();
  }, [showInbox, ediImports, emailImports, customerPortal]);

  useEffect(() => {
    toggleEdiImports();
  }, [ediImports]);

  useEffect(() => {
    toggleEmailImports();
  }, [emailImports]);

  useEffect(() => {
    toggleShowCalculateEffectiveRateButton();
  }, [showCalculateEffectiveRate]);

  useEffect(() => {
    toggleCreateLoadFromPdf();
  }, [createLoadFromPdf]);

  useEffect(() => {
    toggleBulkLoadImport();
  }, [bulkLoadImport]);

  useEffect(() => {
    toggleShowDriverGrossPayEstimate();
  }, [showDriverGrossPayEstimate]);

  useEffect(() => {
    toggleTeamDrivers();
  }, [teamDrivers]);

  useEffect(() => {
    toggleSplitLoads();
  }, [splitLoads]);

  useEffect(() => {
    toggleCreateInvoiceOnMobile();
  }, [createInvoiceOnMobile]);

  useEffect(() => {
    toggleDriverSettlements();
  }, [driverSettlements]);

  useEffect(() => {
    toggleLiveTracking();
  }, [liveTracking]);

  useEffect(() => {
    toggleCustomerPortal();
  }, [customerPortal]);

  useEffect(() => {
    toggleAddressLocationLink();
  }, [addressLocationLink]);

  useEffect(() => {
    toggleRateConBanner();
  }, [showUnsupportedRateConBanner]);

  useEffect(() => {
    toggleRouteInfoV2();
  }, [routecomponentV2]);

  useEffect(() => {
    toggleFuelTransactions();
  }, [fuelTransactions]);

  useEffect(() => {
    toggleMetabase();
  }, [metabase]);

  useEffect(() => {
    toggleOrganizations();
  }, [showOrganizations]);

  useEffect(() => {
    toggleStopSignature();
  }, [stopSignature]);

  useEffect(() => {
    toggleQboSetCustomLoadField();
  }, [qboSetCustomLoadField]);

  useEffect(() => {
    toggleMaintenanceLog();
  }, [maintenanceLog]);

  useEffect(() => {
    toggleAutomaticallyFilterDispatchUserLoads();
  }, [automaticallyFilterDispatchUserLoads]);

  useEffect(() => {
    toggleOtherExpenses();
  }, [otherExpenses]);

  useEffect(() => {
    toggleSendQboInvoiceInternally();
  }, [sendQboInvoiceInternally]);

  useEffect(() => {
    toggleFlexibleTagging();
  }, [flexibleTagging]);

  useEffect(() => {
    toggleDownloadAppBanner();
  }, [downloadAppBanner]);

  useEffect(() => {
    toggleZipAttachments();
  }, [zipAttachments]);

  useEffect(() => {
    toggleCalendarV2();
  }, [calendarV2]);

  useEffect(() => {
    toggleOtherPayInDriverPayModal();
  }, [otherPayItemsInDriverPay]);

  useEffect(() => {
    toggleRateConModelDropdown();
  }, [butlerRateConModelDropdown]);

  useEffect(() => {
    toggleDummyDeleteAccountButton();
  }, [dummyDeleteAccountButton]);

  useEffect(() => {
    toggleMarkAsDelivered();
  }, [enableMarkAsDelivered]);

  useEffect(() => {
    toggleSystemCameraForDocumentUpload();
  }, [systemCameraForDocumentUpload]);

  useEffect(() => {
    toggleShowDriverProfile();
  }, [showDriverProfile]);

  useEffect(() => {
    toggleFactoring();
  }, [factoring]);

  useEffect(() => {
    toggleIntercomWidget();
  }, [intercomWidget]);

  useEffect(() => {
    toggleReportsUnderConstruction();
  }, [reportsUnderConstruction]);

  useEffect(() => {
    toggleAvailabilityPage();
  }, [availabilityPage]);

  useEffect(() => {
    toggleMobileLoadInbox();
  }, [mobileLoadInbox]);

  useEffect(() => {
    toggleEquipmentFile();
  }, [equipmentFile]);

  useEffect(() => {
    toggleNotificationBanner();
  }, [notificationBanner]);

  useEffect(() => {
    toggleDownForMaintenance();
  }, [downForMaintenance]);

  useEffect(() => {
    toggleAndroidCreateGeoLink();
  }, [androidCreateGeoLink]);

  useEffect(() => {
    toggleWarningHelper();
  }, [warningHelper]);

  const providerData: FeatureFlagsContextProps = {
    enableSettingsPageAccess,
    enableAccountSettingsPage,
    enableIntegrationsSettingsPage,
    enableChargbeeTrialBanner,
    enableShowWelcome,
    enableInvoices,
    enableTruckbaseInvoicing,
    enableDynamicWebTwain,
    enableShowInbox,
    enableEdiImports,
    enableEmailImports,
    enableShowCalculateEffectiveRateButton,
    enableCreateLoadFromPdf,
    enableBulkLoadImport,
    enableDriverGrossPayEstimate,
    enableTeamDrivers,
    enableSplitLoads,
    enableSendInvoiceOnMobile,
    enableDriverSettlements,
    enableLiveTracking,
    enableCustomerPortal,
    enableRouteInfoV2,
    enableRateConBanner,
    enableFuelTransactions,
    enableMetabase,
    enableAddressLocationLink,
    enableOrganizations,
    enableStopSignature,
    enableQboSetCustomLoadField,
    enableMaintenanceLog,
    enableAutomaticallyFilterDispatchUserLoads,
    enableOtherExpenses,
    enableSendQboInvoiceInternally,
    enableFlexibleTagging,
    enableDownloadAppBanner,
    enableZipAttachments,
    enableCalendarV2,
    enableOtherPayInDriverPayModal,
    enableButlerRateConModelDropdown,
    enableDummyDeleteAccountButton,
    enableMarkAsDeliveredBtn,
    enableSystemCameraForDocumentUpload,
    enableShowDriverProfile,
    enableFactoring,
    enableIntercomWidget,
    enableReportsUnderConstruction,
    enableMobileLoadInbox,
    enableEquipmentFile,
    enableAvailabilityPage,
    enableNotificationBanner,
    enableDownForMaintenance,
    enableWarningHelper,
    enableAndroidCreateGeoLink
  };

  return (
    <FeatureFlagsContext.Provider value={providerData}>{children}</FeatureFlagsContext.Provider>
  );
};

const useFeatureFlags = (): FeatureFlagsContextProps => useContext(FeatureFlagsContext);

export default useFeatureFlags;
