import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { endOfDay, endOfWeek, isSameWeek, startOfDay, startOfWeek } from 'date-fns';
import { CalendarDisplayContentType, CalendarViewType } from './calendar.model';
import { FilterParams } from '../../../providers/DispatchViews/DispatchViews.model';

interface CalendarState {
  startDate: Date;
  endDate: Date;
  period: Date[];
  displayContentType: CalendarDisplayContentType;
  viewType: CalendarViewType;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  unassignedItemsFilterParams?: FilterParams;
  assignedItemsFilterParams?: FilterParams;
}

const initialState: CalendarState = {
  startDate: startOfWeek(Date.now(), { weekStartsOn: 1 }), // 1 === Monday
  endDate: endOfWeek(Date.now(), { weekStartsOn: 1 }),
  period: [],
  displayContentType: 'driver',
  viewType: CalendarViewType.Week,
  weekStartsOn: 1,
  unassignedItemsFilterParams: undefined,
  assignedItemsFilterParams: undefined
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setDisplayContentType: (state, action: PayloadAction<CalendarDisplayContentType>) => {
      state.displayContentType = action.payload;
    },
    setPeriod: (state, action: PayloadAction<Date[]>) => {
      state.period = action.payload;
    },
    setViewType: (state, action: PayloadAction<CalendarViewType>) => {
      state.viewType = action.payload;
      const startDate = new Date(state.startDate);

      if (state.viewType === CalendarViewType.TwoDays) {
        const today = new Date();

        if (isSameWeek(startDate, today, { weekStartsOn: state.weekStartsOn })) {
          state.startDate = startOfDay(today);
          state.endDate = endOfDay(today.setDate(today.getDate() + 1));
          return;
        }

        state.startDate = startOfDay(startDate);
        state.endDate = endOfDay(startDate.setDate(startDate.getDate() + 1));
      }

      if (state.viewType === CalendarViewType.Week) {
        state.startDate = startOfWeek(startDate, { weekStartsOn: state.weekStartsOn });
        state.endDate = endOfWeek(startDate, { weekStartsOn: state.weekStartsOn });
      }
    },
    goBackOnePeriod: (state) => {
      const newStartDate = new Date(state.startDate);
      const newEndDate = new Date(state.endDate);

      // If view is set to TwoDays, we want to move 1 day at a time in the past
      if (state.viewType === CalendarViewType.TwoDays) {
        newStartDate.setDate(newStartDate.getDate() - 1);
        newEndDate.setDate(newEndDate.getDate() - 1);
      } else {
        newStartDate.setDate(newStartDate.getDate() - state.viewType);
        newEndDate.setDate(newEndDate.getDate() - state.viewType);
      }

      state.startDate = newStartDate;
      state.endDate = newEndDate;
    },
    goForwardOnePeriod: (state) => {
      const newStartDate = new Date(state.startDate);
      const newEndDate = new Date(state.endDate);

      // If view is set to TwoDays, we want to move 1 day at a time in the future
      if (state.viewType === CalendarViewType.TwoDays) {
        newStartDate.setDate(newStartDate.getDate() + 1);
        newEndDate.setDate(newEndDate.getDate() + 1);
      } else {
        newStartDate.setDate(newStartDate.getDate() + state.viewType);
        newEndDate.setDate(newEndDate.getDate() + state.viewType);
      }

      state.startDate = newStartDate;
      state.endDate = newEndDate;
    },
    goToToday: (state) => {
      if (state.viewType === CalendarViewType.TwoDays) {
        const today = new Date();

        state.startDate = startOfDay(today);
        state.endDate = endOfDay(today.setDate(today.getDate() + 1));
      }

      if (state.viewType === CalendarViewType.Week) {
        state.startDate = startOfWeek(Date.now(), { weekStartsOn: state.weekStartsOn });
        state.endDate = endOfWeek(Date.now(), { weekStartsOn: state.weekStartsOn });
      }
    },
    setUnassignedItemsFilterParams: (state, action: PayloadAction<FilterParams | undefined>) => {
      state.unassignedItemsFilterParams = action.payload;
    },
    setAssignedItemsFilterParams: (state, action: PayloadAction<FilterParams | undefined>) => {
      state.assignedItemsFilterParams = action.payload;
    }
  }
});

export const {
  goBackOnePeriod,
  goForwardOnePeriod,
  goToToday,
  setDisplayContentType,
  setPeriod,
  setViewType,
  setAssignedItemsFilterParams,
  setUnassignedItemsFilterParams
} = calendarSlice.actions;

export default calendarSlice.reducer;
